.scaling-svg-container {
  position: relative; 
  width:90vw;
  height: 90vh;   
 }

 .scaling-svg {
  position: absolute; 
  height: 100%; 
  width: 100%; 
  left: 0; 
  top: 0;
 }

.react-transform-wrapper{
  margin: auto;
}

